import Styled from 'styled-components'
import {urlChannel} from "../../utils";

export const Button = Styled.button`
    width: ${props => props.width ? props.width : '100%'};
    color: ${props => props.outline ? '#40545E' : '#FFFFFF'};
    background-color: ${props => props.outline ? 'transparent' : props.bgDisabled ? props.bgDisabled :  !props.bgDisabled && localStorage.getItem('channel') === 'genial' ? '#050922' : '#92B0B3'};
    border: 0;
    font-weight: bold;
    font-size: 14px;
    padding: 7px 10px;
    border: 1px solid ${props => props.bgDisabled ? props.bgDisabled : localStorage.getItem('channel') === 'genial' ? '#050922' : '#92B0B3'};
    
    svg {
        margin-top: -3px;
    }

:active{
    opacity: 0.6;
}
`
