import React, {useState} from 'react'

import Checkbox from "components/CheckboxCustom";
import ReserveDate from "components/Safra/SearchComponents/ReserveDate";
import Passengers from "components/Safra/SearchComponents/Passengers";
import Offers from "components/Safra/SearchComponents/Offers"

import {TbPlaneDeparture, TbPlaneArrival} from 'react-icons/tb';
import {LocationAirfare} from "components/Safra/SearchComponents/LocationAirfare";
import SearchAirport from "components/Safra/SearchAirport"

export default function () {

    return (
        <div className="box-search">
            <div style={{display: 'flex', gap: '20px'}} >
                <SearchAirport
                    placeholder='Origem'
                    tipo='origem'
                />

                <SearchAirport
                    placeholder='Destino'
                    tipo='destino'
                />
            </div>

            <div className="d-grid gap-3">
                <ReserveDate
                    iconStart={<TbPlaneDeparture/>}
                    iconEnd={<TbPlaneArrival/>}
                    placeholderStart="Ida"
                    placeHolderEnd="Volta"
                    page="airfare"
                />


            </div>

            <Passengers />
        </div>
    )
}
