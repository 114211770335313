import React from "react";

import {Modal} from "react-bootstrap";
import Button from "components/Button";
import {FaArrowLeft } from "react-icons/fa6";


export const ModalApp = ({show, handleModal, isMap = false, children, label = ''}) => {
    return  <Modal
        centered={true}
        show={show}
        onHide={ handleModal }
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className={`rounded-0 ${isMap && 'p-0'} `}
    >
        <Modal.Header className={"custom-modal-header"}>
            <FaArrowLeft onClick={handleModal}/>
            <span className="text-center w-100">{label}</span>
        </Modal.Header>
        <Modal.Body className={`text-center p-0 m-0`}>
            {children}

            {isMap &&
                <div className="mx-3 position-relative">
                    <Button onClick={handleModal} className="close-button-bottom">Fechar</Button>
                </div>
            }
        </Modal.Body>
    </Modal>
}

