import api from '_config/http'

export function getAirports(airports) {

    const body = JSON.stringify({
        "query": airports
    });

    return api.post('/airports/search', body)
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }

        })
        .catch(error => console.log(error))
}
