import Styled from "styled-components";
import {MdLocationOn} from 'react-icons/md'
export const Container = Styled.div`
margin: 6%;

.resume {
    color: #00003C;
    font-weight: 700;
}

.star {
    float: right;
}

.hotel-name {
    color: #00003C;
    font-weight: 400;
    font-size: 17px;
}

.map {
    color: #00003C;
    font-weight: 400;
    font-size: 12px;
   // cursor: pointer;
    text-decoration: underline;
}

.label {
    color: #00003C;
    font-size: 17px;
}

`;

export const LocationMap = Styled(MdLocationOn)`
color: #00003C;
height: 20px;
width: 20px;
`

export const ButtonLocation = Styled.button`

background: none;
border: none;
color: #6C6C6C;
font-weight: 400;
font-size: 13px;
text-decoration-line: underline;
`

export const CheckIn = Styled.div`
margin-top: 20px;
display: flex;
justify-content: space-between;

.top{
    margin-top: 7px;
}

p{
    color: #00003C;
    font-weight: 400;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    text-align: center;

    span{
        color: rgba(38, 50, 56, 0.5);
        font-weight: 400;
        font-size: 13px;
    }
    
    @media(max-width: 800px) {
        margin-right: 20px;
       
}
}
`;

export const Line = Styled.div`
    border-bottom: 1px solid #C6C6C5;
    margin: 25px 0 20px;
`

export const Resumo = Styled.div`
background-color: #F0F0F0;
padding: 20px;
margin-top: 20px;

.title-resume{
    color: #00003C;
    font-weight: 400;
    font-size: 18px;
    font-weight: bold;
}

.title{
    color: #00003C;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0;
   
}
.value{
    color: #6C6C6C;
    font-size: 13px;
}

.points{
    color: #40545E;
    font-weight: 400;
    font-size: 15px;   

}

.valor-total{
    color: #40545E;
    font-weight: 400;
    font-size: 17px;
    margin-top: 30px;
    
}
`

export const Span = Styled.span`
 float: right; 
 color: #6C6C6C !important;
 font-size: 13px;
`

export const Cupom = Styled.div`
background-color: #F0F0F0;
margin-top: 30px;
display: flex;
padding: 10px;
margin-bottom: 30px;

input{
    border: 0;
    background-color: #F0F0F0;
    border-bottom: 1px solid #C6C6C5;
    float: right;
    outline: none;
    width: 40%;
    margin-left: 20px;
}

label{
    color: #40545E;
    font-size: 17px;
    font-weight: 700;
}
`

export const Points = Styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin-top: 6px;
margin-bottom: 20px;

.dots {
  flex: 1;
  border-bottom: 1px dashed #818181;
  margin-right: 10px;
  margin-left: 10px;
}
`;

export const Title = Styled.div`
    color: #40545E;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0; 
`;

export const ValuePoints = Styled.div`
    color: #6C6C6C;
    font-size: 13px;
`;

export const Values = Styled.div`

display: flex;
flex-direction: row;
align-items: center;
padding-top: 20px;

.dots {
  flex: 1;
  border-bottom: 1px dashed #818181;
  margin-right: 10px;
  margin-left: 10px;
}

`;
