import Styled from 'styled-components'

export const Button = Styled.button`
background: none;
border: 1px solid #40545E;
border-radius: 6px;
width: 78px;
color: #00003C;
font-size: 14px;
    
`


export const ContainerContentFilter = Styled.div`
display: flex;
flex-direction: column;
background-color: #fff;

.label {
    font-size: 14px;
    color: #00003C;
    font-weight: bold;
    text-align: start;
    margin-top: 20px;
}
`

export const DivInput = Styled.div`

margin: 6%;
p{
    color: rgba(71, 70, 70, 0.5);
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
}

span{
    float: right;
    font-size: 12px;
    font-weight: 400;
}

.valor{
    margin-top: -12px;
    color: black;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
}
`

export const DivStars = Styled.div`

text-align: start;


p{
    color: #00003C;
    font-weight: 700;
    font-size: 14px;
}

.css-1vooibu-MuiSvgIcon-root {
    width: 0.9em;
    margin-top: -2px;
    fill: #00003C;
}

`

export const Line = Styled.div`
border: 1px solid #707070;

`

export const ContainerCheckbox = Styled.div`

margin-top: -10px;
p{
    color:#00003C;
    font-size: 14px;
    font-weight: bold;
}

.top{
    margin-top: 20px;
}

@media(max-width: 800px) {{
margin-top: 0;
}
`

export const Div = Styled.div`
display:flex;
margin-bottom:  -10px; 

label{
    color: #00003C;
    font-weight: 400;
    font-size: 14px;
}

input{
    margin-right: 10px;
    color: #7F7F7F;
}
`

