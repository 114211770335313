import React, {useState} from "react";

import {FaUserAlt} from 'react-icons/fa'
import {HiMinusSm, HiPlusSm} from "react-icons/hi";
import {ModalApp} from "components/Modal";
import Button from "components/Button";
import Radio from "components/CheckboxCustom";
import {classes, initial_passengers as passengers} from 'utils'
import {IoLocationSharp} from "react-icons/io5";
import { ContentRoom } from "./styles";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";

export default function Passengers() {
    const [openPassageiro, setOpenPassageiro] = useState(false)
    const [openClasse, setOpenClasse] = useState(false)

    const [adults, setAdults] = useState(0)
    const [children, setChildren] = useState(0)
    const [baby, setBaby] = useState(0)

    const [passageiros, setPassageiros] = useState('')

    const [classe, setClasse] = useState('');

    const handleModalPassageiro = () => {
        formatPassageiros()
        setOpenPassageiro(!openPassageiro);
    };

    const handleModalClasse = () => {
        setOpenClasse(!openClasse);
    };

    const formatPassageiros = () => {
        let str = '';

        if (adults > 0) {
            str = str + `${adults} Adulto(s) `
            localStorage.setItem('adults', adults);
        }

        if (children > 0) {
            str = str + `${children} Criança(s) `
            localStorage.setItem('children', children)
        }

        if (baby > 0) {
            str = str + `${baby} Bebê(s)`
            localStorage.setItem('baby', baby)
        }

        setPassageiros(str)
    }

    const handlePeople = (type, action) => {

        if (action === 'add') {
            switch (type) {
                case 'adults':
                    setAdults(adults + 1);
                    break;
                case 'children':
                    setChildren(children + 1)
                    break;
                case 'baby':
                    setBaby(baby + 1);
                    break;
            }
        } else if (action === 'remove') {
            switch (type) {
                case 'adults':
                    setAdults(adults - 1);
                    break;
                case 'children':
                    setChildren(children - 1)
                    break;
                case 'baby':
                    setBaby(baby - 1);
                    break;
            }
        }
    }

    const InputControl = ({type, placeholder, value = null}) => {
            return (
                <div className="input-group">
                    <div className="w-100">
                        <label className="label-modal-custom">{placeholder}</label>
                    </div>
    
                    <div className="w-100" style={{display:"flex", padding: "15px 0" , flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <span role={"button"}>
                                <CiCircleMinus style={{width: "40px", height:"40px", color: '#C6C6C5'}} onClick={() => handlePeople(type,'remove')}></CiCircleMinus>
                            </span>
                        </div>
                        <div>
                            <span id={"qtd_" + type}>{value}</span>
                        </div>
                        <div>
                            <span role={"button"}>
                                <CiCirclePlus style={{width: "40px", height:"40px", color: '#C6C6C5'}} onClick={() => handlePeople(type,'add')}></CiCirclePlus>
                            </span>
                        </div>
                    </div>
                </div>
            )
        }
    

    function handleClasse(value) {
        setClasse(value);

        localStorage.setItem('cabin', value);
        localStorage.setItem('class', value);
        localStorage.setItem('radio-class', value);
    }

    return (
        <div className="content-custom">
            <>
                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <input
                                className="form-control form-control-custom border-custom"
                                value={passageiros}
                                onClick={handleModalPassageiro}
                                placeholder="Nº de passageiros"
                            />
                        </div>
                    </div>

                    <div className="col">
                        <div className="input-group">
                            <input
                                className="form-control form-control-custom border-custom"
                                value={classe}
                                onClick={handleModalClasse}
                                placeholder="Classe"
                            />
                        </div>
                    </div>
                </div>
            </>

            <ModalApp show={openPassageiro} handleModal={handleModalPassageiro} label="Passageiros" >
                <ContentRoom>

                    <div className="row">
                        <div className="col d-grid">

                            <InputControl placeholder="Adulto" type="adults" value={adults} />
                            <InputControl placeholder="Criança" type="children" value={children} />
                            <InputControl placeholder="Bebês" type="baby" value={baby} />

                        </div>
                    </div>
                </ContentRoom>
                <div className="mt-4 pb-2">
                    <Button type="button" width="200px" onClick={handleModalPassageiro}>Continuar</Button>
                </div>
            </ModalApp>

            <ModalApp show={openClasse} handleModal={handleModalClasse} label="Classe">
                <div className="p-4 bg-content">
                    <div className="row">
                        <div className="col d-grid gap-4">
                            <div className="d-grid gap-2">
                                {classes.map((item, index) =>
                                    <div className="" key={item.name}>
                                        <div className="input-group">
                                            <span className="input-group-custom border-0">
                                               <Radio
                                                    name="same"
                                                    type="radio"
                                                    value={item.name}
                                                    onChange={() => {
                                                        handleClasse(item.name)
                                                    }}
                                               />
                                            </span>
                                            <label className="label-custom color-custom border-0">{item.label}</label>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <Button type="button" width="200px" onClick={handleModalClasse}>Continuar</Button>
                </div>
            </ModalApp>
        </div>
    );
}
