import React, {useState, useReducer, useEffect} from "react";

import { FaUserAlt } from 'react-icons/fa'
import { HiMinusSm, HiPlusSm } from "react-icons/hi";

import { ModalApp } from "components/Modal";
import Button from "components/Button";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";


const ButtonSafra = React.lazy(() => import('components/Safra/Button'))

import { ContentRoom } from "./styles";

import { useApp } from "contexts/AppContext";
import { GuestsReducer } from "contexts/reducers/guests/reducers";
import { addAction, removeAction, increaseAgeAction, decreaseAgeAction } from "contexts/reducers/guests/actions";
import {
    changeGuestsAction,
    setTravellersHotelAction
} from "contexts/reducers/hotels/actions";
import {isChannel} from "../../../utils";
import {useGenialApp} from "../../../contexts/GenialAppContext";

export default function RoomQuantity() {

    const { hotelState, hotelDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { guestsSearchHotel, travellers } = hotelState
    const [ selectedButton, setSelectedButton] = useState('ll')

    const INITIAL_STATE_GUEST = {
        adults: guestsSearchHotel?.adults,
        children: guestsSearchHotel?.children,
        childrenInputAge: guestsSearchHotel?.childrenInputAge
    }

    const [open, setOpen] = useState(false);
    const [dataTravellers, setDataTravellers] = useState(travellers)

    const [guestsState, dispatch] = useReducer(
        GuestsReducer,
        INITIAL_STATE_GUEST
    )

    const { adults, children, childrenInputAge} = guestsState

    const maxPeople = 8;

    const maxAge = 10;

    const add = (type) => {
        if(dataTravellers.length >= maxPeople) {
            return
        }

        setDataTravellers([...dataTravellers, {
            "type": type,
            "firstName": "",
            "lastName": "",
            "birthDate": "",
            "gender": "",
            "document": ""
        }])

        dispatch(addAction(type))
    }

    const remove = (type) => {

        let elementValue = document.getElementById("qtd_" + type).textContent

        if(dataTravellers.length === 1 || parseInt(elementValue) === 0) {
            return
        }

        setDataTravellers([...dataTravellers.filter((_,index) => index !== 0)])

        dispatch(removeAction(type))
    }

    const increaseAge = (value, position) => {

        if (value > maxAge) {
            return
        }

        dispatch(increaseAgeAction(position))
    }

    const decreaseAge = (position) => {
        dispatch(decreaseAgeAction(position))
    }

    const handleModal = () => {
        hotelDispatch(changeGuestsAction(guestsState))
        hotelDispatch(setTravellersHotelAction(dataTravellers))
        setOpen(!open);
    };

    const InputControl = ({type, placeholder, value = null}) => {
        return (
            <div className="input-group">
                <div className="w-100">
                    <label className="label-modal-custom">{placeholder}</label>
                </div>

                <div className="w-100" style={{display:"flex", padding: "15px 0" , flexDirection: "row", justifyContent: "space-between"}}>
                    <div>
                        <span role={"button"}>
                            <CiCircleMinus style={{width: "40px", height:"40px", color: '#C6C6C5'}} onClick={() => remove(type)}></CiCircleMinus>
                        </span>
                    </div>
                    <div>
                        <span id={"qtd_" + type}>{value}</span>
                    </div>
                    <div>
                        <span role={"button"}>
                            <CiCirclePlus style={{width: "40px", height:"40px", color: '#C6C6C5'}} onClick={() => add(type)}></CiCirclePlus>
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    const InputControlAge = ({placeholder, value = null, position}) => {
        return (
            <div className="input-group">
            <label className="label-custom">{placeholder}</label>
                <span className="input-group-custom">
                    <span className="px-1" role="button"
                          onClick={() => decreaseAge(position) }>
                        <HiMinusSm/>
                    </span>
                </span>
                <span className="input-group-custom px-0">{value}</span>
                <span className="input-group-custom">
                   <span className="px-1" role="button"
                         onClick={() => increaseAge(value, position)}>
                        <HiPlusSm/>
                    </span>
                </span>
            </div>
        )
    }

    useEffect(() => {
        var channel = localStorage.getItem('channel');

        setSelectedButton(channel)
    })

    return (
        <div className="content-custom">
            <div className="input-group" onClick={handleModal}>
                <label className="d-flex flex-grow-1 label-custom">Hóspedes</label>

                <span className="input-group-custom">
                    { adults + children } <span className="svg-custom"><FaUserAlt/></span>
                </span>

            </div>

            <ModalApp show={open} handleModal={handleModal} label="Hóspedes">

                <ContentRoom>
                    <div className="row">
                        <div className="col d-grid">

                            <InputControl placeholder="Adulto" type="adults" value={adults} />
                            <InputControl placeholder="Criança" type="children" value={children} />

                            <div className="row">

                                { childrenInputAge?.length > 0 &&
                                    <div className="mb-3 text-start text-muted">
                                        <small>*idade da data que será feito o check-in.</small>
                                    </div>
                                }

                                { childrenInputAge?.length > 0 && childrenInputAge.map((item, i) =>
                                    <div className="col-6 mb-3" key={i}>
                                        <InputControlAge
                                            placeholder={`Criança ${i + 1}`}
                                            value={item.age}
                                            position={i}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ContentRoom>

                <div className="custom-modal-footer">
                    {selectedButton === 'safra' &&
                        <>
                            <ButtonSafra type="button" width="200px" onClick={ handleModal }>Salvar</ButtonSafra>
                        </>
                    }
                    {selectedButton === 'll' &&
                        <>
                            <Button type="button" width="200px" onClick={ handleModal }>Continuar</Button>
                        </>

                    }
                </div>
            </ModalApp>
        </div>
    );
}
