import Styled from "styled-components";
import {MdLocationOn} from 'react-icons/md'
export const Container = Styled.section`
margin-top: 125px;
p{
    margin-bottom: 0;
}
`;


export const HotelInformation = Styled.div`

padding-top: 20px;


.hotel{
    display: flex;
    justify-content: space-between;

    h3{
        color: #40545E;
        font-weight: 700;
    }
}

.map {
    background: none;
    border: none;
    color: #6C6C6C;
    font-weight: 400;
    font-size: 11px;
    display: flex;
    align-items: end; 
    gap: 2px;
    
}

.css-iljtu2-MuiRating-root{
       color: #40545E;
    }

`

export const LocationMap = Styled(MdLocationOn)`
color: #6C6C6C;
height: 18px;
width: 18px;
`

export const ButtonLocation = Styled.button`

background: none;
border: none;
color: #6C6C6C;
font-weight: 400;
font-size: 11px;
margin-bottom: 20px;
`

export const DivAbout = Styled.div`
 margin-bottom: 1rem;

.sobre-hotel{
   margin-top: 10px;
   margin-bottom: 5px; 
   color: #263238;
   font-weight: bold;
   font-size: 14px;
}

.quarto{
   margin-top: 30px;
   color: #263238;
   font-weight: bold;
   font-size: 14px;
}

.button-top{
    
}
`;
export const Paragraph = Styled.div`
color: #474646;
font-size: 14px;
`;
export const BoxRoom = Styled.div`

.button-tab {
    background-color: #F0F0F0;
    color: #263238;
    font-weight: 700;
    font-size: 14px;
    
    svg { 
        margin-top: -2px;
        color: #9e9e9e;
    }
}

.tab-content {
    background-color: #F0F0F0;
    padding-bottom: 20px;
}   

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    border: 0;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #F0F0F0;
    border: 0;
}


`;

export const Rooms = Styled.div`
padding: 20px 25px;
position: relative;

.label{
    color: #474646;
    font-weight: 700;
    font-size: 18px;
}
`;

export const RoomContent = Styled.div`
background-color: white;

box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
margin-top: 10px;
padding: 15px;

.btn {
    width: 146px;
    height: 30px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

p{
    margin-bottom: 0;
}

.label{
    color: #40545E;
    font-weight: 700;
    font-size: 17px;
}

.label:first-letter { text-transform: capitalize; }

.price-icon {
   display: flex;
   align-items: center;
   gap: 5px;
   color: rgba(71, 70, 70, 0.5); 
}

.ver-mais{
    margin-top: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #474646;
    font-size: 13px;
    cursor: pointer;
    svg{
        margin-top: -5px;
    }
}

.div-info{
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 13px;
    
    .info-1{
        background-color: #40545E;
        padding: 2px 5px;
        color: white;
    }
    
    .info-2{
        background-color: #E6E6E6;
        padding: 2px 4px;
        color: black;
    }
}

.price{
    display: grid;
    justify-content: space-between;
    
    p{
        color: #00003C;
        font-size: 20px;
        font-weight: bold;
    }
    
    p > span {
        font-size: 15px;
    }
    
    .span{
        color: rgba(38, 50, 56, 0.5);
        margin-top: -5px;
        font-size: 12px;
    }

}

.divText{
    margin: 30px 0;
}

.title{
   margin-top: 10px;
   margin-bottom: 5px; 
   color: #263238;
   font-weight: bold;
   font-size: 14px;
}

.paragraph{
    color: #474646;
    font-size: 14px;
}

.btn-check {
    // position: absolute;
}
`;

export const Rules = Styled.div`
background-color: white;
margin: 6%;

.rule{
    color: #263238;
    font-weight: bold;
    font-size: 14px;
}


.div-check{
    display: flex;
    gap: 20%;

    p{
        display: flex;
        flex-direction: column;
        color: #263238;
        font-size: 12px;

        span{
            text-align: center;
            color: rgba(38, 50, 56, 0.5);
            font-size: 12px;
            margin-bottom: 5px;
        }
    }
}

p{
    color: #263238;
    font-size: 14px;
    margin-bottom: 0;
}

.info{
    color: rgba(38, 50, 56, 0.5);
    font-size: 13px;
    margin-bottom: 10px;
 
}
`;
