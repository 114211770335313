import React, {useEffect, useState} from "react";

import {Container} from 'react-bootstrap';
import Carousel from "react-bootstrap/Carousel";

import {
    Banner,
    ContainerTicketData,
    ContainerContent,
    SearchTicket,
    CheckboxContainer,
    Radio,
    AlignRadio,
    Label
} from "./styles";


import {carouselApi, carouselGenialApi} from "./carousel";
import {isChannel, search_option, urlChannel} from 'utils'

import {useNavigate, useLocation } from "react-router-dom";

import Button from "components/Button";
import {CarouselDefault} from "components/Carousel";
import SearchCar from "components/SearchCar";
import SearchHotel from "components/SearchHotel";
import SearchAirfare from "../../components/SearchAirfare";
import api from "../../_config/http";

export default function Home() {

    const [selected, setSelected] = useState(0);
    const [search, setSearch] = useState('compra_hotel')

    const navigate = useNavigate()

    const handleSearchHotels = () => {
        navigate('/hotels-search-result')
    }

    const handleSearchCars = () => {
        navigate('/cars-search-result')
    }

    const handleRadioSelect = (e) => {
        console.log(e)
        setSelected(e);
    }

    useEffect(() => {
        if (localStorage.getItem('channel') === 'genial') {
            const radio = document.getElementById('radio1');
            if (radio) {
                radio.checked = true;
                radio.click();
            }

            setSelected(search_option[1].value)
        }
    }, [])

    var carousel = ! isChannel('genial') ? carouselApi : carouselGenialApi;

    return (
        <>
            <Banner style={{marginTop: '8rem'}} >
                <Carousel variant="dark">

                    {carousel.map((item, index) =>
                        <Carousel.Item key={index}>
                            <img className="d-block" src={item.image} alt={item.label}/>
                        </Carousel.Item>
                    )}
                </Carousel>
            </Banner>
            <Container>
                <ContainerTicketData>
                    <ContainerContent className="shadow">
                        <SearchTicket>Pesquisar Viagens</SearchTicket>
                        <CheckboxContainer>
                            {search_option.map((option, index) =>

                                <AlignRadio key={index} id={`align_${option.value}`}>
                                    <Radio
                                        type="radio"
                                        name="order"
                                        id={`radio${index}`}
                                        value={option.value}
                                        onClick={(e) => handleRadioSelect(e.target.value)}
                                        defaultChecked={!(!!index)}
                                    />
                                    <label htmlFor={`radio${index}`}>{option.label} </label>
                                </AlignRadio>

                            )}
                        </CheckboxContainer>
                        {/*  -------------------------Pesquisa---------------------- */}
                        { selected === 'compra_passagem' &&
                            <>
                                <SearchAirfare />
                                <div className="text-center">
                                    <Button onClick={() => handleSearchCars()} width="200px">Buscar</Button>
                                </div>
                            </>
                        }
                        { selected === 'compra_hotel' &&
                            <>
                                <SearchHotel buttonLabel="Continuar"/>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={() => handleSearchHotels()}
                                        width="200px"
                                    >
                                        Continuar
                                    </Button>
                                </div>
                            </>
                        }

                        { selected === 'compra_carro' &&
                            <>
                                <SearchCar/>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={() => handleSearchCars()}
                                        width="200px"
                                    >
                                        Continuar
                                    </Button>
                                </div>
                            </>
                        }


                        {/*  -------------------------Pesquisa---------------------- */}
                    </ContainerContent>
                </ContainerTicketData>
            </Container>
        </>
    );
}
